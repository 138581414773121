import React from 'react';

import BlogArticle from 'components/blog/blogArticle';
import { authorKalina as author } from 'data/authors';

import cover from 'img/blog/hate-website/cover-hate.png';
import styled from 'styled-components';

const Container = styled.div`
  h3,
  h2 {
    font-weight: 700;
    letter-spacing: 0.01em;
    margin: 2em 0 0.8em;
    line-height: 2rem;
  }
  h3 {
    font-size: 1.1rem;
  }
  h2 {
    font-size: 1.5rem;
  }
`;

const Content = () => {
  return (
    <Container>
      <p>
        <strong>
          Have you noticed that your website hasn’t been getting the most attention? Does it tend to
          push visitors away? Poor user experience can be the cause of your high page abandonment
          rates and low visitor-to-lead conversion rates. Having a good site is crucial looking at
          today’s competition. The question is why aren’t people the biggest fans of your website?
          What are you doing wrong?
        </strong>
      </p>

      <h2>1. Your website is super slow</h2>

      <p>
        With the current advancement of technology, having a slow website is something users will
        not tolerate. Attention spans are shorter than ever and users are becoming more impatient
        with loading websites. Studies show that{' '}
        <a
          rel="noopener nofollow noreferrer"
          target="_blank"
          href="https://neilpatel.com/blog/loading-time/"
        >
          47% of consumers
        </a>{' '}
        expect a web page to load in 2 seconds or less. It’s frustrating to deal with a page that
        takes forever to load. This can also make them angry or disappointed. It can affect your
        conversion rates and brand perception - website visitors are most likely to blame you
        instead of their mobile service providers. That’s why it’s important to optimize your site’s
        load performance - page load time is impacted by many factors that should be looked into.
        Videos, image sizes and code all contribute to how long it takes to load. Some things you
        can try to improve your page load time are compressing your images, minifying code and
        loading videos in the background.{' '}
      </p>

      <h2>2. You disclude important information</h2>

      <p>
        Your website should let customers know who you are and what you do. Without this
        information, they won’t have any idea on how your brand can help them. Consumers also need
        answers - urgently. If it’s difficult to find these answers, they will most likely be
        aggravated with your website. If they find the answers they’re looking for, they’ll return
        to your site in the future and are more likely to trust your brand. Try to be as informative
        as possible with your content, but don’t be too overwhelming. Navigate users to where they
        want to click. The same thing goes for your “Contact Us” section - it tends to be similar
        and generic on most pages. When a customer wants to reach you, they want to do it
        immediately. It’s pointless to have them fill out a form and wait for a response. Make it
        known on your website that people can contact you through social media or email.{' '}
      </p>

      <h2>3. Your forms constantly crash</h2>

      <p>
        Speaking of filling out forms, avoid server errors at all costs. Any unnecessary forms and
        information that need to be filled out will only cause stress and annoyance. There’s no
        reason to be adding too many elements that will only crash and trash users’ data. Excessive
        pop-ups can also cause site issues. There can also be a plugin error, service provider
        error, hosting error, domain error or even a traffic error. Some of these issues can be
        easily resolved with a simple change to your site. Look into the issue to decide what the
        best solution is.{' '}
      </p>

      <h2>4. You don’t help your customers</h2>

      <p>
        Customers need solutions. What is your site offering that can help them? One example can be
        adding user-friendly internal linking. This is helpful in providing relevant information and
        improves the organic ranking for important pages on your website. Pointing users to the
        right pages will leave them satisfied. Enhance the visitor&apos;s experience and include
        links that can only benefit them. Something to consider is using various tools to improve
        User Experience.{' '}
        <a rel="noopener noreferrer" target="_blank" href="https://livesession.io">
          LiveSession
        </a>{' '}
        records how a user interacts with your website, analyzes their behavior and draws
        conclusions from it. It makes sure that your visitors don’t get stuck when browsing your
        site and they “land” exactly where you need them to be.{' '}
      </p>

      <h2>5. You add baseless claims</h2>

      <p>
        Nowadays, no one will believe you if you don’t back your claims up with statistics or proven
        facts. Providing no concrete evidence to support how good your service is will have people
        feeling unsure about your brand and can lead them to not trusting your services. If you make
        a claim, have some past reports or data that you can use to back it up. Infographics are
        always effective.{' '}
      </p>

      <h2>6. Too many keywords</h2>

      <p>
        If someone sees that your website is full of paragraphs and overwhelming content, they will
        most likely leave the page. Don’t write texts packed with keywords - it’s a huge mistake. A
        few keywords are okay, but stuffing them into your content will only{' '}
        <a
          rel="noopener nofollow noreferrer"
          target="_blank"
          href="https://blog.hubspot.com/marketing/seo-mistakes-to-avoid"
        >
          hurt your chances
        </a>{' '}
        of being found in search. Getting traffic is important, but this isn’t the right way to
        achieve it. No one will want to read through all of your paragraphs without yawning. There’s
        a huge difference between search engine optimized content and over-optimized content.{' '}
      </p>

      <p>
        The solution to your problem is to revamp your website and give it a makeover. Consider
        implementing new strategies and methods - you’d be surprised on how much they can help.
        Start making changes, forget your old ways and soon you’ll be seeing new fans of your
        website.{' '}
      </p>
    </Container>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'Why do people hate your website?',
  description: `Having a good site is crucial
  looking at today’s competition. `,
  url: '/blog/why-do-people-hate-your-website',
  author,
  img: cover,
  date: '2019-08-08',
  category: '',
  imgStyle: { borderBottom: '1px solid rgba(0,0,0,0.125)' },
  group: 'Articles',
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    group={frontmatter.group}
    descriptionSocial="Have you noticed that your website hasn’t been getting the most attention? Does it tend to push visitors away? Poor user experience can be the cause of your high page abandonment rates and low visitor-to-lead conversion rates. Having a good site is crucial looking at today’s competition. The question is why aren’t people the biggest fans of your website? What are you doing wrong?"
    img={frontmatter.img}
  />
);
